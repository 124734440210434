const colors = {};

const sizes = {
  maxWidth: '1280px',
  mediaSmallWidth: '1400px',
  mediaMobileWidth: '900px',
  headerHeight: '60px',
};

const urls = {
  googleIcon: process.env.PUBLIC_URL + '/images/ic_google.png',
  appleIcon: process.env.PUBLIC_URL + '/images/ic_apple.png',
  headerLogo: process.env.PUBLIC_URL + '/images/img_logo_myb.png',
  topSectionBg: process.env.PUBLIC_URL + '/images/img_topsection_bg.png',
  topSectionBgMobile: process.env.PUBLIC_URL + '/images/img_topsection_bg_mobile.png',
  rewardSectionItem: process.env.PUBLIC_URL + '/images/img_rewardsection_item.png',
  rewardSectionItemMobile: process.env.PUBLIC_URL + '/images/img_rewardsection_item_mobile.png',
  rewardSectionIcon1: process.env.PUBLIC_URL + '/images/img_rewardsection_icon1.png',
  rewardSectionIcon2: process.env.PUBLIC_URL + '/images/img_rewardsection_icon2.png',
  quizSectionItem: process.env.PUBLIC_URL + '/images/img_quizsection_item.png',
  quizSectionItemMobile: process.env.PUBLIC_URL + '/images/img_quizsection_item_mobile.png',
  quizSectionItem2: process.env.PUBLIC_URL + '/images/img_quizsection_item2.png',
  badgeSectionItem: process.env.PUBLIC_URL + '/images/img_badgesection_item.png',
  badgeSectionItemMobile: process.env.PUBLIC_URL + '/images/img_badgesection_item_mobile.png',
  exhcnageSectionItem: process.env.PUBLIC_URL + '/images/img_exchangesection_item.png',
  downloadSectionItem: process.env.PUBLIC_URL + '/images/img_downloadsection_item.png',
  exchangeSectionLogoList: [
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo1.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo2.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo3.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo4.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo5.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo6.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo7.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo8.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo9.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo10.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo11.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo12.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo13.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo14.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo15.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo16.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo17.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo18.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo19.png',
    process.env.PUBLIC_URL + '/images/img_exchangesection_logo20.png',
  ],
};

const theme = {
  colors,
  sizes,
  urls,
};

export default theme;
