import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

import ScreenProvider from './contexts/screenProvider';

import App from './App';
import theme from './styles/themes';
import { customSnackbarStyles } from './styles/common';

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <ScreenProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        Components={customSnackbarStyles}
      >
        <App />
      </SnackbarProvider>
    </ScreenProvider>
  </ThemeProvider>
);
