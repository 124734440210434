import React from 'react';

import useViewport from './hooks/useViewport';

import Homepage from './pages';

import './styles/reset.css';
import './styles/normalize.css';

function App() {
  useViewport();

  return (
    <React.Fragment>
      <React.Fragment>
        <Homepage />
      </React.Fragment>
    </React.Fragment>
  );
}

export default App;
