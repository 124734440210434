import React from 'react';

import MarqueeImage from '../../components/marqueeImage';

import theme from '../../styles/themes';
import {
  LeftWrapper,
  LogoListWrapper,
  ExchangeImage,
  RightWrapper,
  SectionContainer,
  SectionDescriptionTypo,
  SectionTitleTypo,
  SectionWrapper,
  TitleWrapper,
  TypoWrapper,
} from './styles';

const ExchangeSection = () => {
  return (
    <SectionContainer>
      <SectionWrapper>
        <LeftWrapper>
          <ExchangeImage src={theme.urls.exhcnageSectionItem} />
        </LeftWrapper>
        <RightWrapper>
          <TypoWrapper>
            <TitleWrapper>
              <SectionTitleTypo>상품 교환도 마음대로</SectionTitleTypo>
            </TitleWrapper>
            <SectionDescriptionTypo>{`리워드는 현금처럼 사용할 수 있어요!\n받은 리워드로 모바일 쿠폰을 교환해서\n현금처럼 사용해보세요.`}</SectionDescriptionTypo>
          </TypoWrapper>
        </RightWrapper>
      </SectionWrapper>
      <LogoListWrapper>
        <MarqueeImage dataList={theme.urls.exchangeSectionLogoList} />
      </LogoListWrapper>
    </SectionContainer>
  );
};

export default React.memo(ExchangeSection);
