import React from 'react';

import useScreen from '../../hooks/useScreen';
import { CONTACT1, CONTACT2, PRIVACY, TERM } from '../../constants/urls';

import {
  AddressTypo,
  BottomWrapper,
  CompanyName,
  Copyright,
  CsEmail,
  CsItem,
  CsLabel,
  CsList,
  CsType,
  Divider,
  FooterContainer,
  FooterWrapper,
  LicenseNumber,
  MiddleWrapper,
  TermsNormal,
  TermsSpecial,
  TermsWrapper,
  TopWrapper,
} from './styles';

const Footer = () => {
  const { isMobile } = useScreen();

  const handleExternal = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <FooterContainer>
      <FooterWrapper>
        <TopWrapper>
          <CompanyName>(주)원셀프월드</CompanyName>
          <TermsWrapper>
            <TermsNormal onClick={() => handleExternal(TERM)}>이용약관</TermsNormal>
            <TermsSpecial onClick={() => handleExternal(PRIVACY)}>개인정보처리방침</TermsSpecial>
          </TermsWrapper>
        </TopWrapper>
        <MiddleWrapper>
          <CsList>
            <CsItem>
              <CsLabel>MyB 마이비</CsLabel>
              <CsType>제휴 및 광고 문의</CsType>
              <CsEmail onClick={() => handleExternal(CONTACT1)}>sales@1self.world</CsEmail>
            </CsItem>

            <CsItem>
              <CsLabel>MyB 마이비</CsLabel>
              <CsType>서비스 관련 문의</CsType>
              <CsEmail onClick={() => handleExternal(CONTACT2)}>support@1self.world</CsEmail>
            </CsItem>
          </CsList>
        </MiddleWrapper>
        <BottomWrapper>
          <AddressTypo>
            <span>주소</span> 서울특별시 강남구 도산대로 317 호림아트센터 1빌딩 11층
            {!isMobile && (
              <>
                <Divider />
                <span>대표</span> 조창현
              </>
            )}
          </AddressTypo>
          {isMobile && (
            <AddressTypo>
              <span>대표</span> 조창현
            </AddressTypo>
          )}
          <LicenseNumber>
            <span>사업자 등록번호</span> 154-86-02838
          </LicenseNumber>
          <Copyright>Copyright©1Self.World Inc. All rights reserved</Copyright>
        </BottomWrapper>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default React.memo(Footer);
