import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f6ff;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 24px 0;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.maxWidth}) {
    padding: 24px 20px;
  }
`;

export const HeaderLogo = styled.img`
  width: 68px;
  height: 32px;
`;
