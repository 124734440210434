import React from 'react';

import useScreen from '../../hooks/useScreen';
import { APPLE_STORE, GOOGLE_PLAY } from '../../constants/urls';

import theme from '../../styles/themes';
import {
  LeftWrapper,
  DownloadImage,
  RightWrapper,
  SectionContainer,
  SectionTitleTypo,
  SectionWrapper,
  TitleWrapper,
  TypoWrapper,
  ButtonWrapper,
  PrimaryButton,
  ButtonImage,
  ButtonTypo,
  DownloadImageMobile,
} from './styles';

const DownloadSection = () => {
  const { isMobile } = useScreen();

  const handleExternal = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <SectionContainer>
      <SectionWrapper>
        <LeftWrapper>
          <TypoWrapper>
            <TitleWrapper>
              <SectionTitleTypo>
                당신의 취향이 {isMobile ? <br /> : ''}소득이 되는 마이비
                <br />
                지금 시작해보세요.
              </SectionTitleTypo>
            </TitleWrapper>
          </TypoWrapper>
          <DownloadImageMobile src={theme.urls.downloadSectionItem} />

          <ButtonWrapper>
            <PrimaryButton onClick={() => handleExternal(APPLE_STORE)}>
              <ButtonImage $src={theme.urls.appleIcon} />
              <ButtonTypo>App Store</ButtonTypo>
            </PrimaryButton>
            <PrimaryButton onClick={() => handleExternal(GOOGLE_PLAY)}>
              <ButtonImage $src={theme.urls.googleIcon} />
              <ButtonTypo>Google Play</ButtonTypo>
            </PrimaryButton>
          </ButtonWrapper>
        </LeftWrapper>
        <RightWrapper>
          <DownloadImage src={theme.urls.downloadSectionItem} />
        </RightWrapper>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default React.memo(DownloadSection);
