import styled from 'styled-components';

export const SectionContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.maxWidth}) {
    padding: 150px 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    padding: 150px 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
    gap: 0;
    padding: 80px 20px;
  }
`;

export const LeftWrapper = styled.div`
  flex: 1;
  position: relative;
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    align-items: center;
  }
`;

export const RightWrapper = styled.div`
  position: relative;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TypoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 100%;
    align-items: center;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SectionTitleTypo = styled.h1`
  color: #191f28;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.3;

  & > span {
    color: #427cff;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 46px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 100%;
    font-size: 28px;
    text-align: center;
  }
`;

export const SectionDescriptionTypo = styled.h2`
  color: #8b94a0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  white-space: pre;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 22px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 14px;
    display: flex;
    text-align: center;
  }
`;

export const DownloadImage = styled.img`
  width: 100%;
  max-width: 282px;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.1));

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    max-width: 240px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    display: none;
  }
`;

export const DownloadImageMobile = styled.img`
  display: none;
  max-width: auto;
  width: 118px;
  margin: 55px 0 60px 0;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    display: flex;
  }
`;

export const ButtonWrapper = styled.div`
  z-index: 2;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
    margin-top: 0;
  }
`;

export const PrimaryButton = styled.div`
  padding: 16px 32px;
  min-width: 238px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: rgba(3, 16, 45, 0.19);
  cursor: pointer;
  &:hover {
    background: rgba(3, 16, 45, 0.12);
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    padding: 16px 28px;
    min-width: 220px;
    max-width: 220px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    padding: 10px 20px;
    min-width: 174px;
    max-width: 174px;
  }
`;

export const ButtonImage = styled.div<{ $src: string }>`
  width: 32px;
  height: 32px;
  background-image: url(${({ $src }) => $src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    width: 30px;
    height: 30px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonTypo = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 16px;
  }
`;
