import styled from 'styled-components';

export const SectionContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f8fd;
  position: relative;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 50px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.maxWidth}) {
    padding: 0 20px 0 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column-reverse;
    gap: 0;
  }
`;

export const LeftWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 0;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    justify-content: center;
    align-items: center;
  }
`;

export const RightWrapper = styled.div`
  flex: 1;
  line-height: 0;
  display: flex;
  align-items: flex-start;
`;

export const TypoWrapper = styled.div`
  width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44px;

  margin-top: 230px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 100%;
    align-items: center;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 30px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SectionTitleTypo = styled.h1`
  width: 352px;
  color: #191f28;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.3;
  white-space: pre;

  & > span {
    color: #427cff;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 48px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 100%;
    font-size: 28px;
  }
`;

export const SectionDescriptionTypo = styled.h2`
  color: #8b94a0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  white-space: pre;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 14px;
    display: flex;
    text-align: center;
  }
`;

export const QuizImage = styled.img`
  width: 100%;
  max-width: 467px;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.1));

  margin-top: 230px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    max-width: 400px;
    margin-left: 3vw;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    margin-top: 20px;
    width: calc(100% - 10vw);
    max-width: auto;
  }
`;

export const QuizSectionBg = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - 40px);
  width: 627px;
  height: 279px;
  background-image: url(${({ theme }) => theme.urls.quizSectionItem2});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    width: calc(627px * 0.7);
    height: calc(279px * 0.7);
    left: calc(50% + 1vw);
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    display: none;
  }
`;
