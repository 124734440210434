import styled from 'styled-components';

export const SectionContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.maxWidth}) {
    padding: 0 20px 0 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
    gap: 0;
  }
`;

export const LeftWrapper = styled.div`
  flex: 1;
  position: relative;
  line-height: 0;
  display: flex;
  align-items: center;
`;

export const RightWrapper = styled.div`
  flex: 1;
  position: relative;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    justify-content: center;
  }
`;

export const TypoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 100%;
    align-items: center;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 30px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SectionTitleTypo = styled.h1`
  color: #191f28;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.3;

  & > span {
    color: #427cff;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 46px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 100%;
    font-size: 28px;
    text-align: center;
  }
`;

export const SectionDescriptionTypo = styled.h2`
  color: #8b94a0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  white-space: pre;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 14px;
    display: flex;
    text-align: center;
  }
`;

export const BedgeImage = styled.img`
  width: 100%;
  max-width: 642px;
  margin-top: 150px;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.1));

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    max-width: 500px;
    margin-top: 100px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    margin-top: 0px;
    max-width: auto;
  }
`;
