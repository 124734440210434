import React, { useEffect, useRef, useState } from 'react';

import { MarqueeContainer, MarqueeImageItem, MarqueeImageList } from './styles';

const MarqueeImage = ({ dataList }: { dataList: string[] }) => {
  const extendedDataList = [...dataList, ...dataList];
  const marqueeRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<number | null>(null);
  const offsetRef = useRef(0);

  const normalSpeed = 0.5;
  const hoverSpeed = parseFloat((normalSpeed / 3).toFixed(2));

  const [speed, setSpeed] = useState(normalSpeed);

  useEffect(() => {
    const marquee = marqueeRef.current;
    if (!marquee) return;

    const scrollMarquee = () => {
      offsetRef.current -= speed;
      if (Math.abs(offsetRef.current) >= marquee.scrollWidth / 2) {
        offsetRef.current = 0;
      }
      marquee.style.transform = `translateX(${offsetRef.current}px)`;
      animationRef.current = requestAnimationFrame(scrollMarquee);
    };

    animationRef.current = requestAnimationFrame(scrollMarquee);

    return () => {
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, [speed]);

  const handleMouseEnter = () => {
    setSpeed(hoverSpeed);
  };

  const handleMouseLeave = () => {
    setSpeed(normalSpeed);
  };

  return (
    <MarqueeContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MarqueeImageList ref={marqueeRef}>
        {extendedDataList.map((logo, index) => (
          <MarqueeImageItem key={`s1-${index}`} src={logo} />
        ))}
      </MarqueeImageList>
    </MarqueeContainer>
  );
};

export default React.memo(MarqueeImage);
