import styled from 'styled-components';

export const MarqueeContainer = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    height: 90px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    height: 52px;
  }
`;

export const MarqueeImageList = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  gap: 50px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    gap: 30px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    gap: 20px;
  }
`;

export const MarqueeImageItem = styled.img`
  width: 120px;
  height: auto;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    width: 90px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 52px;
  }
`;
