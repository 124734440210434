import React from 'react';

import theme from '../../styles/themes';
import { HeaderContainer, HeaderLogo, HeaderWrapper } from './styles';

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLogo src={theme.urls.headerLogo} />
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default React.memo(Header);
