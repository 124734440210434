import React from 'react';

import useScreen from '../../hooks/useScreen';

import theme from '../../styles/themes';
import {
  LeftWrapper,
  QuizSectionBg,
  QuizImage,
  RightWrapper,
  SectionContainer,
  SectionDescriptionTypo,
  SectionTitleTypo,
  SectionWrapper,
  TitleWrapper,
  TypoWrapper,
} from './styles';

const QuizSection = () => {
  const { isMobile } = useScreen();

  return (
    <SectionContainer>
      <SectionWrapper>
        <LeftWrapper>
          <QuizImage src={isMobile ? theme.urls.quizSectionItemMobile : theme.urls.quizSectionItem} />
        </LeftWrapper>
        <RightWrapper>
          <TypoWrapper>
            <TitleWrapper>
              <SectionTitleTypo>
                재밌는 설문과 퀴즈가
                <br />
                <span>매일 업데이트</span> 돼요.
              </SectionTitleTypo>
            </TitleWrapper>
            <SectionDescriptionTypo>{`재미와 보상을 한번에!\n다양한 퀴즈에 참여하고 취향 소득을 받으세요.\n친구를 초대하고 출석체크에 참여하여\n더 많은 보상을 받을 수 있습니다.`}</SectionDescriptionTypo>
          </TypoWrapper>
          <QuizSectionBg />
        </RightWrapper>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default React.memo(QuizSection);
