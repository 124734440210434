import styled from 'styled-components';

export const FooterContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f5f7;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 68px 0 140px 0;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.maxWidth}) {
    padding: 40px 20px 60px 20px;
  }
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    margin-bottom: 50px;
  }
`;

export const MiddleWrapper = styled.div`
  display: flex;
  margin-bottom: 86px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    margin-bottom: 60px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    gap: 0;
  }
`;

export const CompanyName = styled.div`
  color: #191f28;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 22px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 16px;
  }
`;

export const TermsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 38px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    gap: 16px;
  }
`;

export const TermsNormal = styled.div`
  color: #8b94a0;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 14px;
  }
`;

export const TermsSpecial = styled.div`
  color: #5c6573;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 14px;
  }
`;

export const CsList = styled.div`
  display: flex;
  gap: 50px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
  }
`;

export const CsItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CsLabel = styled.div`
  color: #8b94a0;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 16px;
  }
`;

export const CsType = styled.div`
  margin-top: 10px;
  color: #8b94a0;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 16px;
    margin-top: 0px;
  }
`;

export const CsEmail = styled.div`
  margin-top: 24px;
  color: #191f28;
  font-size: 32px;
  font-weight: 700;
  line-height: 26px;
  text-decoration-line: underline;
  cursor: pointer;
  &:hover {
    color: #5c6573;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 28px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    margin-top: 5px;
    font-size: 20px;
  }
`;

export const AddressTypo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #8b94a0;
  font-size: 20px;
  font-weight: 500;
  span {
    font-weight: 700;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const LicenseNumber = styled.div`
  color: #8b94a0;
  font-size: 20px;
  font-weight: 500;
  span {
    font-weight: 700;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const Copyright = styled.div`
  color: #8b94a0;
  font-size: 20px;
  font-weight: 500;
  span {
    font-weight: 700;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 12px;
  background-color: #ced5e0;
`;
