export const APPLE_STORE =
  'https://apps.apple.com/kr/app/myb-%EB%A7%88%EC%9D%B4%EB%B9%84-%EC%89%BD%EA%B3%A0-%EA%B0%84%ED%8E%B8%ED%95%98%EA%B2%8C-%EC%A6%90%EA%B8%B0%EB%8A%94-%ED%80%B4%EC%A6%88%ED%98%95-%EC%95%B1%ED%85%8C%ED%81%AC/id6476365625?l=en-GB';

export const GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=im.myb.android&pli=1';

export const TERM = 'https://1self.world/term-conditions';

export const PRIVACY = 'https://1self.world/privacy-policy';

export const CONTACT1 = 'mailto:sales@1self.world';

export const CONTACT2 = 'mailto:support@1self.world';
