import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface IScreenContext {
  isMobile: boolean;
}

export const ScreenContext = React.createContext<IScreenContext | null>(null);

const ScreenProvider = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

  return <ScreenContext.Provider value={{ isMobile }}>{children}</ScreenContext.Provider>;
};

export default ScreenProvider;
