import React from 'react';

import useScreen from '../../hooks/useScreen';

import theme from '../../styles/themes';
import {
  LeftWrapper,
  RewardImage,
  RightWrapper,
  SectionContainer,
  SectionDescriptionTypo,
  SectionTitleTypo,
  SectionWrapper,
  TitleIcon,
  TitleIconWrapper,
  TitleWrapper,
  TypoWrapper,
} from './styles';

const RewardSection = () => {
  const { isMobile } = useScreen();

  return (
    <SectionContainer>
      <SectionWrapper>
        <LeftWrapper>
          <TypoWrapper>
            <TitleWrapper>
              <SectionTitleTypo>
                설문 참여만 해도
                <br />
                쌓이는
                <span> 취향소득</span>
              </SectionTitleTypo>
              <TitleIconWrapper>
                <TitleIcon src={theme.urls.rewardSectionIcon1} />
                <TitleIcon src={theme.urls.rewardSectionIcon2} />
              </TitleIconWrapper>
            </TitleWrapper>
            <SectionDescriptionTypo>{`필요한 시간 단 1분!\n잠깐의 참여로 가장 큰 혜택을 받아보세요.\n가입 첫 달 최대 20,000 B포인트를 받을 수 있어요.\n놓치지 마세요!`}</SectionDescriptionTypo>
          </TypoWrapper>
        </LeftWrapper>
        <RightWrapper>
          <RewardImage src={isMobile ? theme.urls.rewardSectionItemMobile : theme.urls.rewardSectionItem} />
        </RightWrapper>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default React.memo(RewardSection);
