import React from 'react';

import { APPLE_STORE, GOOGLE_PLAY } from '../../constants/urls';

import theme from '../../styles/themes';
import {
  ButtonImage,
  ButtonTypo,
  ButtonWrapper,
  PrimaryButton,
  SectionBackground,
  SectionContainer,
  SectionDescriptionTypo,
  SectionTitleTypo,
  SectionWrapper,
} from './styles';

const TopSection = () => {
  const handleExternal = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <SectionContainer>
      <SectionWrapper>
        <SectionTitleTypo>쉽고 간편하게 즐기는 퀴즈형 앱테크</SectionTitleTypo>
        <SectionDescriptionTypo>MyB 마이비는 짧고 재미있는 취향 퀴즈를 큰 보상으로 연결합니다.</SectionDescriptionTypo>
        <ButtonWrapper>
          <PrimaryButton onClick={() => handleExternal(APPLE_STORE)}>
            <ButtonImage $src={theme.urls.appleIcon} />
            <ButtonTypo>App Store</ButtonTypo>
          </PrimaryButton>
          <PrimaryButton onClick={() => handleExternal(GOOGLE_PLAY)}>
            <ButtonImage $src={theme.urls.googleIcon} />
            <ButtonTypo>Google Play</ButtonTypo>
          </PrimaryButton>
        </ButtonWrapper>
        <SectionBackground />
      </SectionWrapper>
    </SectionContainer>
  );
};

export default React.memo(TopSection);
