import React from 'react';

import useScreen from '../../hooks/useScreen';

import theme from '../../styles/themes';
import {
  LeftWrapper,
  BedgeImage,
  RightWrapper,
  SectionContainer,
  SectionDescriptionTypo,
  SectionTitleTypo,
  SectionWrapper,
  TitleWrapper,
  TypoWrapper,
} from './styles';

const BadgeSection = () => {
  const { isMobile } = useScreen();

  return (
    <SectionContainer>
      <SectionWrapper>
        <LeftWrapper>
          <TypoWrapper>
            <TitleWrapper>
              <SectionTitleTypo>
                뱃지를 얻으면
                <br />더 많은 보상의 기회가!
              </SectionTitleTypo>
            </TitleWrapper>
            <SectionDescriptionTypo>
              {`다양한 형태의 뱃지를 모아보세요.\n뱃지를 모으면 추가 보상을 받을 수 있는`}
              {isMobile ? '\n' : ' '}
              {`이벤트와 채널이 늘어납니다.`}
            </SectionDescriptionTypo>
          </TypoWrapper>
        </LeftWrapper>
        <RightWrapper>
          <BedgeImage src={isMobile ? theme.urls.badgeSectionItemMobile : theme.urls.badgeSectionItem} />
        </RightWrapper>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default React.memo(BadgeSection);
