import styled from 'styled-components';

export const SectionContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f6ff;
  position: relative;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SectionTitleTypo = styled.h1`
  max-width: 450px;
  margin-top: 80px;
  color: #191f28;
  text-align: center;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.3;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    max-width: 400px;
    margin-top: 70px;
    font-size: 46px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    max-width: 264px;
    margin-top: 50px;
    font-size: 32px;
  }
`;

export const SectionDescriptionTypo = styled.h2`
  margin-top: 16px;
  color: #8b94a0;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.5;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 22px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    max-width: 260px;
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  z-index: 2;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    flex-direction: column;
  }
`;

export const PrimaryButton = styled.div`
  padding: 16px 32px;
  min-width: 238px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: rgba(3, 16, 45, 0.19);
  cursor: pointer;
  &:hover {
    background: rgba(3, 16, 45, 0.12);
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    padding: 16px 28px;
    min-width: 220px;
    max-width: 220px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    padding: 10px 20px;
    min-width: 174px;
    max-width: 174px;
  }
`;

export const ButtonImage = styled.div<{ $src: string }>`
  width: 32px;
  height: 32px;
  background-image: url(${({ $src }) => $src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    width: 30px;
    height: 30px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonTypo = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    font-size: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    font-size: 16px;
  }
`;

export const SectionBackground = styled.div`
  z-index: 1;
  width: 100vw;
  max-width: 1750px;
  margin-top: -70px;
  background-image: url(${({ theme }) => theme.urls.topSectionBg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 41.41%;

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaSmallWidth}) {
    margin-top: -140px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mediaMobileWidth}) {
    padding-bottom: 71.47%;
    margin-top: -90px;
    background-image: url(${({ theme }) => theme.urls.topSectionBgMobile});
    background-size: cover;
  }
`;
