import React from 'react';

import Header from '../layouts/header';
import Footer from '../layouts/footer';
import TopSection from '../layouts/topSection';
import RewardSection from '../layouts/rewardSection';
import QuizSection from '../layouts/quizSection';
import BedgeSection from '../layouts/bedgeSection';
import ExchangeSection from '../layouts/exchangeSection';
import DownloadSection from '../layouts/downloadSection';
import NewsSection from '../layouts/newsSection';

import { MainContainer } from '../styles/common';

const HomePage = () => {
  return (
    <MainContainer>
      <Header />
      <TopSection />
      <RewardSection />
      <QuizSection />
      <BedgeSection />
      <ExchangeSection />
      <DownloadSection />
      <NewsSection />
      <Footer />
    </MainContainer>
  );
};

export default React.memo(HomePage);
